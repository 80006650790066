import {
  maxBoostablePropertiesSelector,
  maxSuperboostablePropertiesSelector,
  propertiesWithBoostingSelector,
  propertiesWithSuperboostingSelector,
} from '../../../selectors/propertiesSummary';

export function getLastErrorAdId(state) {
  return state.tryToPublishErrorAdId ?? {};
}

export function boostedPropertiesLimitReached(state) {
  const maxBoostableProperties = maxBoostablePropertiesSelector(state);
  const boostedProperties = propertiesWithBoostingSelector(state);
  if (!maxBoostableProperties) {
    return false;
  }
  if (!boostedProperties) {
    return false;
  }
  return boostedProperties >= maxBoostableProperties;
}

export function superboostedPropertiesLimitReached(state) {
  const maxSuperboostableProperties =
    maxSuperboostablePropertiesSelector(state);
  const superboostingProperties = propertiesWithSuperboostingSelector(state);
  if (!maxSuperboostableProperties) {
    return false;
  }
  if (!superboostingProperties) {
    return false;
  }

  return superboostingProperties >= maxSuperboostableProperties;
}

export function showSuperboostingLimitReachedModal(state) {
  const boostingLimitReached = boostedPropertiesLimitReached(state);
  const superboostingLimitReached = superboostedPropertiesLimitReached(state);
  return !boostingLimitReached && superboostingLimitReached;
}
